/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import ButtonLink from '../ButtonLink/ButtonLink.jsx';
import { Image as Img, RichText } from '@sitecore-jss/sitecore-jss-react';

import withErrorBoundary, { ErrorBoundaryConditions } from '../../utils/withErrorBoundary.jsx';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import forStorybookV2 from '../../utils/forStorybook.jsx';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';

import './homepage-hero.scss';
import {
    ButtonVariantEnum,
    HomepageHeroAccentColorEnum,
    HomepageHeroBackgroundColorEnum,
    ThemeEnum,
    LinkTargetValueEnum,
} from '../../utils/enums.js';
import { setAnimationInterObserver } from '../../utils/observers.jsx';

const homepageHero = 'zn-homepage-hero';

const HomepageHero = ({ t, fields, params, sitecoreContext }) => {
    const {
        PageURL,
        Title,
        EyebrowLeft,
        EyebrowRight,
        ImageDesktop,
        ImageMobile,
        Description,
        CTAText,
        CTAAltText,
    } = fields;

    const {
        BackgroundColor,
        AccentColor,
        CustomBackground,
        CustomBackgroundLightness,
        EnableAnimation,
    } = params;

    const animationEnabled = EnableAnimation && !sitecoreContext?.pageEditing;

    useEffect(() => {
        if (animationEnabled) {
            // Scroll fade up reveal
            const fadeUpSelector = `.${homepageHero} .fadeIn`;
            const fadeUpClass = 'fadeInActive';
            // Scroll reveal
            const scrollSelector = `.${homepageHero} .expandDown`;
            const scrollClass = 'expandDownActive';
            // Heading reveal
            const headingRevealSelector = `.${homepageHero} .headingFadeIn`;
            const headingRevealClass = 'headingFadeInActive';
            // Headding accent
            const headingAccentSelector = `.${homepageHero} .expandRight`;
            const headingAccentClass = 'expandRightActive';
            // Adds the class that activates the animation whenever the components are in the view
            setAnimationInterObserver(fadeUpSelector, fadeUpClass);
            setAnimationInterObserver(scrollSelector, scrollClass);
            setAnimationInterObserver(headingRevealSelector, headingRevealClass);
            setAnimationInterObserver(headingAccentSelector, headingAccentClass);
        }
    }, []);

    return (
        <div
            className={classnames(`${homepageHero}`, {
                [`${homepageHero}--dark`]:
                    BackgroundColor === HomepageHeroAccentColorEnum.navy ||
                    (BackgroundColor !== HomepageHeroAccentColorEnum.blue &&
                        CustomBackgroundLightness === ThemeEnum.dark),
                [`${homepageHero}--blue`]: BackgroundColor === HomepageHeroAccentColorEnum.blue,
                [`${homepageHero}__animate`]: animationEnabled,
            })}
            style={{
                backgroundColor: `#${CustomBackground}` || '',
            }}
        >
            <div className={`${homepageHero}__image-container`}>
                {sitecoreContext.pageEditing && <p>Desktop Image</p>}
                <Img
                    field={ImageDesktop}
                    className={classnames(
                        `${homepageHero}__image`,
                        { [`${homepageHero}__image--editing`]: sitecoreContext?.pageEditing },
                        { [`${homepageHero}__image--desktop`]: !sitecoreContext.pageEditing },
                        { fadeIn: animationEnabled }
                    )}
                />
                {sitecoreContext.pageEditing && <p>Mobile Image</p>}
                <Img
                    field={ImageMobile}
                    className={classnames(
                        `${homepageHero}__image`,
                        { [`${homepageHero}__image--editing`]: sitecoreContext?.pageEditing },
                        { [`${homepageHero}__image--mobile`]: !sitecoreContext.pageEditing },
                        { fadeIn: animationEnabled }
                    )}
                />
            </div>
            <div className={classnames(`${homepageHero}__content`)}>
                {EyebrowLeft?.value || EyebrowRight?.value ? (
                    <div
                        className={classnames(`${homepageHero}__eyebrow`, {
                            fadeIn: animationEnabled,
                        })}
                    >
                        {EyebrowLeft?.value && <RichText field={EyebrowLeft} />}
                        {EyebrowLeft?.value && EyebrowRight?.value && (
                            <div className={classnames(`${homepageHero}__eyebrow-divider`)}>•</div>
                        )}
                        {EyebrowRight?.value && <RichText field={EyebrowRight} />}
                    </div>
                ) : null}
                <div
                    className={classnames(`${homepageHero}__title`, {
                        headingFadeIn: animationEnabled,
                    })}
                >
                    <RichText
                        field={Title}
                        className={classnames(`${homepageHero}__title-content`)}
                    />
                </div>
                <div
                    className={classnames(
                        `${homepageHero}__accent`,
                        `${homepageHero}__accent--${AccentColor?.toLowerCase()}`,
                        { expandRight: animationEnabled }
                    )}
                />
                <div
                    className={classnames(`${homepageHero}__description`, {
                        fadeIn: animationEnabled,
                    })}
                >
                    <RichText field={Description} />
                </div>
                <div className={classnames(`${homepageHero}__CTA`, { fadeIn: animationEnabled })}>
                    <ButtonLink
                        fields={{
                            LinkURL: {
                                value: {
                                    text: CTAText?.value,
                                    href: PageURL?.url,
                                    title: CTAAltText?.value,
                                    target: LinkTargetValueEnum.self,
                                },
                            },
                        }}
                        params={{
                            Theme:
                                BackgroundColor === HomepageHeroBackgroundColorEnum.navy ||
                                BackgroundColor === HomepageHeroAccentColorEnum.blue ||
                                CustomBackgroundLightness === ThemeEnum.dark
                                    ? ThemeEnum.dark
                                    : ThemeEnum.light,
                            Variant: ButtonVariantEnum.tertiary,
                        }}
                    />
                </div>
            </div>
            <div
                className={classnames(`${homepageHero}__scroll`, {
                    [`${homepageHero}__scroll--dark`]:
                        BackgroundColor === HomepageHeroBackgroundColorEnum.navy ||
                        BackgroundColor === HomepageHeroAccentColorEnum.blue ||
                        CustomBackgroundLightness === ThemeEnum.dark,
                    expandDown: animationEnabled,
                })}
            >
                <p
                    className={classnames(`${homepageHero}__scroll-text`, {
                        [`${homepageHero}__scroll-text--dark`]:
                            BackgroundColor === HomepageHeroBackgroundColorEnum.navy ||
                            BackgroundColor === HomepageHeroAccentColorEnum.blue ||
                            CustomBackgroundLightness === ThemeEnum.dark,
                        fadeIn: animationEnabled,
                    })}
                >
                    {t('Scroll')}
                </p>
            </div>
        </div>
    );
};

const HomepageHeroSitecore = withSitecoreContext()(
    withErrorBoundary([ErrorBoundaryConditions.fields, ErrorBoundaryConditions.params])(
        HomepageHero
    )
);
export const HomepageHeroStorybook = forStorybookV2(HomepageHeroSitecore);
export default withTranslation()(HomepageHeroSitecore);
